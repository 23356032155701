<template>
    <div>
        <h3 v-show="loading">加载中...</h3>
        <h5 class="text-muted">点击图片可放大预览，如需保存到本地请右击鼠标选择“图片另存为...”</h5>
        <div class="row mb-5" v-if="remark">
            <div class="col-12">
                <h5>数据备注</h5>
                <span>{{ remark }}</span>
            </div>
        </div>
        <div class="row">
            <div class="col-12" v-if="remark">
                <h5>附件</h5>
            </div>
            <div class="col-sm-12 col-md-3" v-for="item in attachments" :key="item">
                <el-image
                    style="width: 100%;"
                    :src="item" 
                    :preview-src-list="attachments">
                </el-image>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        dataInputId: {
            type: Number,
            default: null
        }
    },
  data () {
    return {
      backendHost: process.env.VUE_APP_BACKEND_TARGET || 'https://guoqi.ycycsw.cn',
      loading: false,
      remark: '',
      attachments: []
    }
  },
  methods: {
    fetchData() {
      this.loading = true;
      this.axios.get(`datainputs/${this.dataInputId}/attachments`)
      .then((res) => {
        if (res.data.errcode == 0) {
            this.remark = res.data.remark
            this.attachments = res.data.attachments.map(x => {
                return this.backendHost + x
            })
        }
        else {
            this.$notifyErr('未能成功加载数据', res.data.errmsg);
        }
        this.loading = false;
      })
      .catch((e) => {
        this.$notifyErr('未能成功加载数据', e.message);
        this.loading = false;
      })
    }
  },
  mounted () {
    this.fetchData()
  }
};
</script>